// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.2.0",
  "buildNumber": "11344",
  "gitSha": "0cb6660ad91a19a4064e6537374c896506add24a",
  "fullVersion": "20.2.0-11344",
  "formattedVersion": "20.2.0 (11344-0cb6660ad91a19a4064e6537374c896506add24a)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
